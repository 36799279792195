#html-body {

    div[data-content-type~="row"] {
        // Common section
        font-family: "canada-type-gibson";
        color: #22262A;
        margin-top: -2px; // trims off white lines caused by background scaling. This shouldnt happen!
        margin-bottom: -2px; // trims off white lines caused by background scaling. This shouldnt happen!
        h2 { font-weight: 600;}
        p {color: #5E696E;}
        .white { p { color: white;} h2 { color: white;} h3 { color: white;} }
        .white-mobile { @include media-breakpoint-only(xs) { color: #ffffff; }}
        .black-mobile { @include media-breakpoint-only(xs) { color: #22262A; }}

        // Breakpoint specifics
        @include media-breakpoint-only(xl) {
            padding: 60px;
            h2 { font-size: 5rem; line-height: 4.6875rem;}
            h3 { font-size: 2rem;}
            h2 + h3 { margin-top:-1rem;}
            p { font-size: 2rem; line-height: 2.75rem;}
            .text-padding { padding: 60px 0px; }
        }

        @include media-breakpoint-only(lg) {
            padding: 54px;
            h2 { font-size: 3.5rem; line-height: 2.875rem; }
            h3 { font-size:1.5rem; }
            h2 + h3 { margin-top:-1rem; }
            p { font-size: 1.3125rem; line-height: 1.875rem;}
            .text-padding { padding: 54px 0px; }
        }

        @include media-breakpoint-only(md) {
            padding: 42px;
            h2 { font-size: 2.25rem; line-height: 1.875rem; }
            h3 { font-size:1.125rem; }
            h2 + h3 { margin-top:-1rem; }
            p { font-size: 1.125rem; line-height: 1.375rem; }
            .text-padding { padding: 42px 0px; }
        }

        @include media-breakpoint-down(sm) {
            padding: 26px;
            h2 { font-size: 1.6875rem; line-height: 1.375rem; }
            h3 { font-size: 1rem;}
            h2 + h3 { margin-top:-1rem; }
            p { font-size: 1rem; line-height: 1.375rem;}
            .text-padding { padding: 26px 0px; }
        }

        // No XS media breakpoint required for text attributes as its the same sizes used in sm - hence the "-down" mixin above

        .product-featured-cards {
            @include media-breakpoint-down(sm) {display: flex; flex-direction: column; justify-content: flex-start; align-items: center;}
            @include media-breakpoint-only(md) {display: flex; flex-direction: column; justify-content: flex-start; align-items: center;}
            @include media-breakpoint-only(lg) {display: flex; flex-direction: row; justify-content: space-around; align-items: flex-start;}
            @include media-breakpoint-only(xl) {display: flex; flex-direction: row; justify-content: space-around; align-items: flex-start;}
        }

        .product-featured-card {
            img {padding: 0 0 1.5rem 0;}
            h2 {text-align: left; margin-bottom: 0.5rem;}
            p {text-align:left;}

            @include media-breakpoint-down(sm) {max-width: 350px; display: flex; flex-direction: column; justify-content: flex-start; align-items: center; h2 {font-size: 1.25rem; line-height: 1.375rem;} p {font-size: 0.9375rem; line-height: 1.375rem;} }
            @include media-breakpoint-only(md) {max-width: 600px; display: flex; flex-direction: row; justify-content: center; align-items: center; h2 {font-size: 1.5rem; line-height: 1.375rem;} p {font-size: 1rem; line-height: 1.375rem;} img {max-width: 275px; padding-right: 1.5rem;} }
            @include media-breakpoint-only(lg) {max-width: 290px; display: flex; flex-direction: column; justify-content: flex-start; align-items: center; h2 {font-size: 1.5rem; margin-bottom: 1rem; line-height: 1.7rem} p {font-size: 1rem; line-height: 1.475rem;} }
            @include media-breakpoint-only(xl) {max-width: 325px; display: flex; flex-direction: column; justify-content: flex-start; align-items: center; h2 {font-size: 1.875rem; margin-bottom: 1rem; line-height: 1.9rem;} p {font-size: 1.125rem; line-height: 1.675rem;} }
        }
    }

    .bg[data-background-type~="image"] {
        @include media-breakpoint-only(xl) { background-size: 3000px auto; }
        @include media-breakpoint-only(lg) { background-size: 2100px auto; }
        @include media-breakpoint-only(md) { background-size: 1365px auto; }
        @include media-breakpoint-only(sm) { background-size: 1024px auto; }
        @include media-breakpoint-only(xs) { background-size: 768px auto; }
    }

    .regular {
        @include media-breakpoint-only(xl) { min-height: 950px; }
        @include media-breakpoint-only(lg) { min-height: 665px; }
        @include media-breakpoint-only(md) { min-height: 432px; }
        @include media-breakpoint-only(sm) { min-height: 324px; }
        @include media-breakpoint-only(xs) { min-height: 243px; }
    }

    .tall {
        @include media-breakpoint-only(xl) { min-height: 1200px; }
        @include media-breakpoint-only(lg) { min-height: 840px; }
        @include media-breakpoint-only(md) { min-height: 546px; }
        @include media-breakpoint-only(sm) { min-height: 410px; }
        @include media-breakpoint-only(xs) { min-height: 307px; }
    }

    .huge {
        @include media-breakpoint-only(xl) { min-height: 1450px; }
        @include media-breakpoint-only(lg) { min-height: 1015px; }
        @include media-breakpoint-only(md) { min-height: 660px; }
        @include media-breakpoint-only(sm) { min-height: 495px; }
        @include media-breakpoint-only(xs) { min-height: 371px; }
    }

    .huge-extra {
        @include media-breakpoint-only(md) { min-height: 690px !important; }
        @include media-breakpoint-only(sm) { min-height: 590px !important; }
    }

    .mobile-fit-content {
        @include media-breakpoint-only(xs) { min-height: fit-content !important; }
    }

    .smaller-text {
        @include media-breakpoint-only(md) { p { font-size: 1rem !important; line-height: 1.275rem !important; } }
        @include media-breakpoint-only(sm) { p { font-size: 0.775rem !important; line-height: 1rem !important; } }
    }

    .whitebg { background-color: #ffffff; }

    .lightgreybg { background-color: #f3f3f3; }

    .darkgreybg { background-color: #5e696d; }

    .whitebg-mobile { @include media-breakpoint-only(xs) { background-color: #ffffff;} }

    .lightgreybg-mobile { @include media-breakpoint-only(xs) { background-color: #f3f3f3;} }

    .darkgreybg-mobile { @include media-breakpoint-only(xs) { background-color: #5e696d;} }

    .slim-top-pad {
        @include media-breakpoint-only(xl) { padding-top: 20px; }
        @include media-breakpoint-only(lg) { padding-top: 18px; }
        @include media-breakpoint-only(md) { padding-top: 14px; }
        @include media-breakpoint-only(sm) { padding-top: 9px; }
        @include media-breakpoint-only(xs) { padding-top: 6px; }
    }

    .slim-bot-pad {
        @include media-breakpoint-only(xl) { padding-bottom: 20px; }
        @include media-breakpoint-only(lg) { padding-bottom: 18px; }
        @include media-breakpoint-only(md) { padding-bottom: 14px; }
        @include media-breakpoint-only(sm) { padding-bottom: 9px; }
    }

    .no-top-pad { padding-top: 0px !important;}
    .no-bot-pad { padding-bottom: 0px !important;}
    .no-vertical-padding {padding-top: 0px !important; padding-bottom: 0px !important; }
    .missing-center-button {display: flex; flex-direction: column; justify-content: center;}
    .left-zero-absolute {left: 0;}

    .desktop-hidden {
        @include media-breakpoint-up(sm) { display: none; }
    }

    .mobile-hidden {
        @include media-breakpoint-only(xs) { display: none; }
    }

    .desktop-absolute {
        @include media-breakpoint-up(sm) { position: absolute; background-color: transparent !important; }
        @include media-breakpoint-only(xs) { min-height: fit-content !important; }
    }

    .desktop-absolute-alt {
        @include media-breakpoint-up(sm) { position: absolute; background-color: transparent !important; z-index:-1; width:100%; }
    }

    .mobile-absolute { @include media-breakpoint-only(xs) { position: absolute;  background-color: transparent !important;} }
    .absolute { position: absolute;  background-color: transparent !important;}

    @include media-breakpoint-up(md) {
        .dcw30 { p { max-width: 30ch; } }
        .dcw40 { p { max-width: 40ch; } }
        .dcw50 { p { max-width: 50ch; } }
        .dcw60 { p { max-width: 60ch; } }
        .dcw70 { p { max-width: 70ch; } }
    }

    @include media-breakpoint-up(sm) {
        .dw30 { width: 30%; }
        .dw40 { width: 40%; }
        .dw50 { width: 50%; }
        .dw60 { width: 60%; }
        .dw70 { width: 70%; }
        .dw80 { width: 80%; }
        .dw90 { width: 90%; }
        .dw100 { width: 100%; }
    }

    @include media-breakpoint-up(sm) {
        .dml30 { margin-left: 30%; }
        .dml40 { margin-left: 40%; }
        .dml50 { margin-left: 50%; }
        .dml60 { margin-left: 60%; }
        .dml70 { margin-left: 70%; }
        .dml80 { margin-left: 80%; }
        .dml90 { margin-left: 90%; }
    }

    @include media-breakpoint-up(sm) {
        .dmr30 { margin-right: 30%; }
        .dmr40 { margin-right: 40%; }
        .dmr50 { margin-right: 50%; }
        .dmr60 { margin-right: 60%; }
        .dmr70 { margin-right: 70%; }
        .dmr80 { margin-right: 80%; }
        .dmr90 { margin-right: 90%; }
    }

    @media (min-width: 768px) {
        .ar3-bg { margin-top: -50px !important; }
    }
}

/*--AR3 voice alert product page styling--*/
.product-airram-3-red-voice-alert .ar3-voice-text {
    color: #373d42 !important;
}
/*--end--*/

/* Used for category content 1 currently - This styling gets added on top of the standard styling above! */
#html-body.catalog-category-view {

    .category-description {
        .bg {
            border-top: 1px solid #cbcbcb;
        }
    }

    .gtech-category-page-builder {
        div[data-content-type~="row"] {
            @include media-breakpoint-up(lg) {
                h2 {
                    font-size: 4.375rem !important;
                    line-height: 4.375rem !important;
                }
                p {
                    font-size: 1.125rem !important;
                    line-height: 1.75rem !important;
                }
            }
            @include media-breakpoint-only(md) {
                h2 {
                    font-size: 2.5rem !important;
                    line-height: 2.625rem !important;
                }
                p {
                    font-size: 1rem !important;
                    line-height: 1.375rem !important;
                }
            }
            @include media-breakpoint-only(sm) {
                h2 {
                    font-size: 1.6875rem !important;
                    line-height: 1.75rem !important;
                }
                p {
                    font-size: 1rem !important;
                    line-height: 1.375rem !important;
                }
            }
            @include media-breakpoint-down(xs) {
                h2 {
                    font-size: 0.9375rem !important;
                    line-height: 1.375rem !important;
                }
                p {
                    font-size: 1rem !important;
                    line-height: 1.375rem !important;
                }
            }
        }
        .bg[data-background-type~="image"] {
            background-color: #F3F3F3;
            @include media-breakpoint-only(xl) {
                background-size: 3000px auto;
            }
            @include media-breakpoint-only(lg) {
                background-size: 2100px auto;
            }
            @include media-breakpoint-down(sm) {
                background-image: none !important;
            }
            //@include media-breakpoint-only(xl) { padding-top: 340px; }
            //@include media-breakpoint-only(lg) { padding-top: 220px; }
            //@include media-breakpoint-only(md) { padding-top: 135px; }
            //@include media-breakpoint-down(sm) { padding-top: 30px; }
            padding-top: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        @include media-breakpoint-up(md) {
            .dml30 {
                margin-left: 30%;
            }
            .dml40 {
                margin-left: 40%;
            }
            .dml50 {
                margin-left: 50%;
            }
            .dml60 {
                margin-left: 60%;
            }
            .dml70 {
                margin-left: 70%;
            }
            .dml80 {
                margin-left: 80%;
            }
            .dml90 {
                margin-left: 90%;
            }
        }
        @include media-breakpoint-down(sm) {
            .dml30 {
                margin-left: 0%;
            }
            .dml40 {
                margin-left: 0%;
            }
            .dml50 {
                margin-left: 0%;
            }
            .dml60 {
                margin-left: 0%;
            }
            .dml70 {
                margin-left: 0%;
            }
            .dml80 {
                margin-left: 0%;
            }
            .dml90 {
                margin-left: 0%;
            }
        }
    }
}
