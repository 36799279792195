.column.main {
    padding-bottom: 0 !important;
}
.page-title {
    display: none;
}
//Hero Banner

.carousel-container {

    //Rotating Carousel of Images
    .fade-in-container {
        position: absolute;
        height: 100% !important;
        width: 65%;
        right: 0px;
        top: 0px;
        @include media-breakpoint-down(sm) {
            width: 100%;
            top: -50px;
        }

    }

    @media(max-width: 768px) {
    .banner-text-container .black-friday-logo {
        display: none !important;
    }
}
    .banner-text-container .black-friday-logo {
        width: 38% !important;
    }

    .blue-underbanner {
        width: 100%;
        height: 2.9375rem !important;
        background: #223149;
        text-align: center;
        color: #fff;
        font-size: 0.875rem;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        flex-direction: row;
        @media (max-width: 768px) {
            height: 50px !important;
            //v-day under banner background
            //background: rgba(183, 133, 146, 0.57);
            color: white;
            font-size: 2rem;
            font-weight: 600;
            flex-direction: column;
            z-index: 2;
        }
        .blue-banner-title {
            margin-right: 3px;
            @media (max-width: 768px) {
                margin-right: 0;
                line-height: 1;
                font-weight: 600;
                font-size: 1rem;
                text-transform: capitalize;
            }
        }
        .blue-banner-promo {
            display: none;
            @media (max-width: 768px) {
                font-size: 1rem;
                display: block;
            }
        }

        &:before {
            content: '';
            position: absolute;
            left: 49.5%;
            top: -18%;
            width: 0;
            height: 0;
            border-bottom: solid 15px #223149;
            border-left: solid 15px transparent;
            border-right: solid 15px transparent;
            z-index: 2;
            // -- Vday 20205 underbanner:before display --
            display:none;
            // -- end --
            @include media-breakpoint-down(sm) {
                top: -10%;
                left: 47%;
                border-bottom: solid 15px $gtech-headings;
            }
        }
    }
    .desktop {
        display: block;
        @include media-breakpoint-down(md) {
            display: none !important;
        }
    }
    .tablet {
        display: none !important;
        @include media-breakpoint-down(md) {
            display: block !important;
        }
        @include media-breakpoint-down(sm) {
            display: none !important;
        }
    }
    .mobile {
        display: none !important;
        @include media-breakpoint-down(sm) {
            display: block !important;
        }
    }
}
#banner-slider-carousel {

    .default-banner {
        height: auto;
        @include media-breakpoint-down(md) {
            height: auto;
        }
        @include media-breakpoint-down(sm) {
            height: 530px !important;
        }
        div {
            height: 100%;
        }
        .black-heading {
            color: #000000;
            text-shadow: 0px 1px 1px #FFFFFF84;
        }
        .banner-text-container {
            position: absolute;
            top: 18%;
            width: 100%;
            height: auto;
            margin: auto;
            left: 0;
            right: 0;
            z-index: 1;
        }
        .shop-now-button {
            padding: 10px 30px;
            color: #FFFFFF;
            //background-color: #FFFFFF;
            border-radius: 5px;
            font-size: 1.2rem;
            font-weight: 500;
            -webkit-box-shadow: 3px 3px 4px 0 rgba(0,0,0,.15);
            box-shadow: 3px 3px 4px 0 rgba(0,0,0,.15);
            @media only screen and (max-width: 1100px) {
                display: none;
            }
            @include media-breakpoint-down(sm) {
                display: none;
                padding: 3px 15px;
                font-size: 1rem;
                font-weight: 500;
                text-transform: capitalize;
                margin-top: 10px;
            }

        }
    }

    //Hylite Banner
    .airramplatinum-banner {

        .banner-text-container{
            top: 10%;
        }

        .small-h2-title {
            font-size: 4.625rem;
            color: $gtech-headings;
            font-weight: 600;
            line-height: .8;
            text-shadow: 2px 2px 3px rgba(0,0,0,.6);
            @include media-breakpoint-down(md) {
                font-size: 3.16rem;
                line-height: .8;
            }
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
        .large-h2-title {
            font-size: 3.45rem;
            color: $gtech-headings;
            font-weight: 600;
            line-height: .7;
            text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.50);
            @include media-breakpoint-down(md) {
                font-size: 1.47rem;
                line-height: .5;
            }
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
        h5 {
            font-size: 1.875rem;
            color: $gtech-headings;
            font-weight: 500;
            text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.50);
            margin-bottom: 10px;
            @include media-breakpoint-down(md) {
                font-size: 1.1rem;
            }
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
        .shop-now-button {
            background-color: $gtech-headings;
        }
    }

    //Hylite Banner
    .hylite-banner {
        .small-h2-title {
            font-size: 7.375rem;
            color: $gtech-headings;
            font-weight: 600;
            line-height: .8;
            text-transform: uppercase;
            text-shadow: 2px 2px 3px rgba(0,0,0,.6);
            @include media-breakpoint-down(md) {
                font-size: 3.16rem;
                line-height: .8;
            }
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
        .large-h2-title {
            font-size: 3.45rem;
            color: $gtech-headings;
            font-weight: 600;
            line-height: .7;
            text-transform: uppercase;
            text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.50);
            @include media-breakpoint-down(md) {
                font-size: 1.47rem;
                line-height: .5;
            }
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
        h5 {
            font-size: 1.875rem;
            color: $gtech-headings;
            font-weight: 500;
            text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.50);
            margin-bottom: 10px;
            @include media-breakpoint-down(md) {
                font-size: 1.1rem;
            }
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }

    //TV Spot Banner
    .tv-banner {
        .large-h2-title {
            font-size: 7rem;
            color: white;
            font-weight: 600;
            line-height: .8;
            text-transform: uppercase;
            text-shadow: 2px 2px 3px rgba(0,0,0,.6);
            @include media-breakpoint-down(md) {
                font-size: 3.66rem;
                margin-bottom: 10px;
                line-height: .8;
            }
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
        .small-h2-title {
            font-size: 5.5rem;
            color: white;
            font-weight: 600;
            line-height: .7;
            text-transform: uppercase;
            text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.50);
            @include media-breakpoint-down(md) {
                font-size: 2rem;
                line-height: .5;
            }
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
        h5 {
            font-size: 1.875rem;
            color: $gtech-headings;
            font-weight: 500;
            text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.50);
            margin-bottom: 30px;
            @include media-breakpoint-down(md) {
                font-size: 1.1rem;
            }
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
        .shop-now-button {
            margin-top: 25px !important;
        }
    }

    //Garden Banner
    .garden-banner {
        .small-h2-title {
            font-size: 6.2rem;
            color: white;
            font-weight: 600;
            line-height: .7;
            text-transform: uppercase;
            text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.50);
            margin-bottom: 15px;
            @include media-breakpoint-down(lg) {
                font-size: 5.7rem;
            }
            @include media-breakpoint-down(md) {
                font-size: 3.16rem;
                line-height: .5;
            }
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
        .large-h2-title {
            font-size: 7.4rem;
            color: white;
            font-weight: 600;
            line-height: .7;
            text-transform: uppercase;
            text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.50);
            @include media-breakpoint-down(lg){
                font-size: 6.9rem;
            }
            @include media-breakpoint-down(md) {
                font-size: 3.75rem;
                line-height: .5;
            }
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
        h5 {
            font-size: 1.875rem;
            color: white;
            font-weight: 500;
            text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.50);
            margin-bottom: 30px;
            @include media-breakpoint-down(md) {
                font-size: 1.1rem;
            }
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }

    //Black Friday Banner
    .black-friday-soon-banner {
        @include media-breakpoint-down(sm) {
            height: 500px !important
        }
        .desktop-animation {
            display: block;
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
        .mobile-animation {
            display: none;
            @include media-breakpoint-down(sm) {
                display: block;
            }
        }
        .banner-text-container {
            @include media-breakpoint-down(sm) {
                padding: 0;
            }
        }
        .blue-underbanner {
            bottom: 0px;
            @include media-breakpoint-down(sm) {
                background-color: black;
                font-weight: 700;
                font-size: 1.5rem;
            }
            &:before {
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }
        }
       /* .black-friday-logo {
            width: 50% !important;
            @include media-breakpoint-down(md) {
                width: 50% !important;
            }
            @include media-breakpoint-down(sm) {
                width: 100% !important;
                object-fit: contain !important;
                position: absolute;
                top: -7px;
            }
        }*/
        #black-friday-offer-roundal{


            @include media-breakpoint-down(xl) {
                position: absolute;
                top: -92px;
                right: 164px;
                width: 150px;
                height: 150px;
            }

            @include media-breakpoint-down(lg) {
                position: absolute;
                top: -90px;
                right: 116px;
                width: 100px;
            }

            @include media-breakpoint-down(md) {
                display: none;
            }
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        .small-h2-title {
            font-size: 6rem;
            color: white;
            font-weight: 600;
            line-height: 1;
            margin-bottom: 50px;
            //margin-top: 40px;
            width: 60%;
            span {
                font-weight: 600;
            }
            @include media-breakpoint-down(lg) {
                font-size: 2.2rem;
            }
            @include media-breakpoint-down(md) {
                font-size: 1.7rem;
                line-height: 1;
            }
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
        h5 {
            font-size: 1.875rem;
            color: white;
            font-weight: 500;
            text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.50);
            margin-bottom: 30px;
            @include media-breakpoint-down(md) {
                font-size: 1.1rem;
            }
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }

    //Simple Banner
    .simple-banner {
        .small-h2-title {
            font-size: 7.4rem;
            color: white;
            font-weight: 600;
            line-height: .7;
            text-transform: uppercase;
            text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.50);
            margin-bottom: 15px;
            @include media-breakpoint-down(md) {
                font-size: 3.75rem;
                line-height: .5;
            }
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
        .large-h2-title {
            font-size: 7.4rem;
            color: white;
            font-weight: 600;
            line-height: .7;
            text-transform: uppercase;
            text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.50);
            @include media-breakpoint-down(md) {
                font-size: 3.75rem;
                line-height: .5;
            }
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
        h5 {
            font-size: 1.875rem;
            color: white;
            font-weight: 500;
            text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.50);
            margin-bottom: 30px;
            @include media-breakpoint-down(md) {
                font-size: 1.1rem;
            }
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }

    //Sale Banner
    .sale-banner {
        .black-heading {
            color: #000000 !important;
            text-shadow: 0px 1px 1px #FFFFFF84 !important;
        }
        .small-h2-title {
            font-size: 5rem;
            color: white;
            font-weight: 600;
            line-height: .7;
            text-transform: uppercase;
            text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.50);
            @include media-breakpoint-down(md) {
                font-size: 2rem;
                line-height: .5;
            }
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
        .large-h2-title {
            font-size: 3rem;
            color: white;
            font-weight: 600;
            line-height: .7;
            text-transform: uppercase;
            text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.50);
            @include media-breakpoint-down(md) {
                font-size: 2rem;
                line-height: .5;
            }
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
        .small-h2-style-2 {
            font-size: 7rem;
            color: white;
            font-weight: 600;
            line-height: .7;
            text-transform: uppercase;
            @include media-breakpoint-down(lg) {
                font-size: 6rem;
            }
            @include media-breakpoint-down(md) {
                font-size: 2rem;
                line-height: .5;
            }
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
        .large-h2-style-2 {
            font-size: 4.8rem;
            color: white;
            font-weight: 600;
            line-height: .7;
            text-transform: uppercase;
            @include media-breakpoint-down(lg) {
                font-size: 4.2rem;
            }
            @include media-breakpoint-down(md) {
                font-size: 2rem;
                line-height: .5;
            }
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
        h5 {
            font-size: 4rem;
            color: white;
            font-weight: 500;
            text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.50);
            margin-bottom: 30px;
            @include media-breakpoint-down(lg) {
                font-size: 3rem;
            }
            @include media-breakpoint-down(md) {
                font-size: 2rem;
            }
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        .sale-conditions {
            font-size: 1rem;
            color: white;
            font-weight: 400;
            margin-top: 3rem;
            @include media-breakpoint-down(lg) {
                margin-top: 2rem;
            }
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }

    div#may-banner-2024 {
        font-weight: 600;
        color: white;
        .banner-text-container {
            display: none;
        }
        .title {
            font-size: 1rem;
            text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.50);
        }
        .off20 {
            font-size: 3rem;
            text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.50);
        }
        .everything {
            font-size: 2rem;
            text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.50);
        }
        .usecode {
            font-size: 1rem;
            color: black;
        }
        .mustend {
            font-size: 0.8rem;
            color: black;
        }
        @include md-and-up {
            .banner-text-container {
                display: block;
            }
        }
        @include lg-and-up {
            .off20 {
                font-size: 4rem;
            }
            .everything {
                font-size: 3rem;
            }
        }
        @include xl-and-up {
            .title {
                font-size: 2rem;
            }
            .off20 {
                font-size: 6rem;
            }
            .everything {
                font-size: 3.5rem;
            }
            .usecode {
                font-size: 2rem;
            }
            .mustend {
                font-size: 1rem;
            }
        }
        @include xxl-and-up {
            .title {
                font-size: 2.5rem;
            }
            .off20 {
                font-size: 7rem;
            }
            .everything {
                font-size: 4.5rem;
            }
            .usecode {
                font-size: 2.5rem;
            }
            .mustend {
                font-size: 1rem;
            }
        }
        @include xs-and-up {
            .mobile {
                display: block !important;
            }
        }
        @include sm-and-up {
            .tablet {
                display: block !important;
            }
            .mobile {
                display: none !important;
            }
        }
        @include md-and-up {
            .desktop {
                display: block !important;
            }
            .tablet {
                display: none !important;
            }
        }
    }



    .owl-carousel .owl-item img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
        @include media-breakpoint-down(sm) {
            height: 400px;
            object-fit: cover;
            background-position: bottom center;
        }
    }
}

// Black Friday 2023 Banner
.black-friday-banner-2023 {
    height: 125vw;
    background-image: url("media/gtech/pages/homepage/BF23/BF-mobile-promo.jpg");
    background-size: cover;
    background-position: center;
    div.bg-video {
        position: absolute;
        width: 100vw;
        overflow: hidden;
        video {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        video.bf-video-desktop {
            display: none;
        }
    }
    @include md-and-up {
        background-image: url("media/gtech/pages/homepage/BF23/BF-desktop-promo.jpg");
        height: 360px;
        div.bg-video {
            display: block;
            height: 360px;
            video.bf-video-desktop {
                display: block;
            }
            video.bf-video-mobile {
                display: none;
            }
        }
    }
    @include lg-and-up {
        height: 480px;
        div.bg-video {
            height: 480px;
        }
    }
    @include xl-and-up {
        height: 600px;
        div.bg-video {
            height: 600px;
        }
    }
    @include xxl-and-up {
        height: 900px;
        div.bg-video {
            height: 900px;
        }
    }

    div.bf-text-container {
        position: relative;
        top: 6rem;
        margin: auto;
        z-index: 1;
        color: white;
        img.bf-lockup {
            height: 30px !important;
            width: auto !important;
            margin-bottom: 1rem;
        }
        h2.title {
            line-height: 1;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 3.375rem;
            span {
                display: block;
            }
        }
        h3.subtitle {
            line-height: 1;
            font-weight: 500;
            display: none;
        }
        a.shop-now {
            line-height: 1;
            font-weight: 500;
            color: black;
            background-color: white;
            border-radius: 0.625rem;
            padding: 0.3rem 2rem 0.5rem 2rem;
        }
        @include sm-and-up {
            img.bf-lockup {
                height: 40px !important;
                width: auto !important;
            }
        }
        @include md-and-up {
            top: 8.375rem;
            h2.title {
                margin: 0;
                span {
                    display: inline;
                }
            }
            h3.subtitle {
                display: block;
            }
            a.shop-now {
                padding: 0.5rem 2rem 0.7rem 2rem;
            }
        }
        @include xxl-and-up {
            img.bf-lockup {
                height: 50px !important;
                width: auto !important;
                margin-bottom: 1.625rem;
            }
            h2.title {
                font-size: 5.625rem;
            }
            h3.subtitle {
                font-size: 2.5rem;
                margin-bottom: 2rem;
            }
            a.shop-now {
                font-size: 1.5rem;
            }
        }
    }
}
div.bf-underbanner {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #223149;
    color: white;
    .title {
        font-size: 1rem;
        font-weight: 500;
    }
}

// Airfox
#promo-banner {
    position: relative;
    height: 125vw;
    background-color: black;
    overflow: hidden;
    div#video-container {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            pointer-events: none;
        }
        video.lg, video.md {
            display: none;
        }
    }


    div#text-container {
        position: relative;
        top: 18%;
        color: white;
        font-weight: 500;
        line-height: 1;
        letter-spacing: -1;
        p#introducing {
            font-size: 1rem;
            margin-bottom: 0;
            span#new {
                text-transform: uppercase;
                font-style: italic;
            }
        }
        img#airfox-logo {
            display: block;
            height: 30px;
            width: auto;
            margin-bottom: 1rem;
            margin-top: 0.5rem;
        }
        p#description {
            font-size: 1rem;
            margin-bottom: 1rem;
            line-height: 1.2;
            display: none;
        }
        a#call-to-action {
            display: block;
            line-height: 1;
            font-size: 1rem;
            font-weight: 500;
            color: black;
            background-color: white;
            border-radius: 5px;
            padding: 0.3rem 2rem 0.5rem 2rem;
            box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.50);
            width: fit-content;
        }
    }

    @include sm-and-up {
        div#text-container {

            img#airfox-logo {
                height: 40px;
            }

            p#description {
            }
        }
    }

    @include md-and-up {
        height: 360px;
        div#video-container {
            video.md {
                display: block;
            }
            video.sm {
                display: none;
            }
        }
        div#text-container {
            p#introducing {
                font-size: 1rem;
                font-weight: 400;
            }
            img#airfox-logo {
                height: 40px;
                margin: 1rem 0;
            }
            p#description {
                display: block;
                font-size: 1rem;
                font-weight: 400;
                margin-bottom: 1.5rem;
                width: 325px;
            }
            a#call-to-action {
                padding: 0.5rem 2rem 0.6rem 2rem;
            }
        }
    }
    @include lg-and-up {
        height: 480px;
        div#text-container {
            p#introducing {
                font-weight: 500;
            }
            p#description {
                font-weight: 500;
            }
        }
    }
    @include xl-and-up {
        height: 600px;
        div#text-container {
            p#introducing {
                font-size: 1.75rem;
            }
            img#airfox-logo {
                height: 50px;
                margin: 1.5rem 0;
            }
            p#description {
                font-size: 1.75rem;
                margin-bottom: 2.5rem;
                width: 400px;
            }
            a#call-to-action {
                font-size: 1.125rem;
                padding: 0.6rem 2.25rem 0.7rem 2.25rem;
            }
        }
    }
    @include xxl-and-up {
        height: 900px;
        div#video-container {
            video.md {
                display: none;
            }
            video.lg {
                display: block;
            }
        }
        div#text-container {
            p#introducing {
                font-size: 1.75rem;
            }
            img#airfox-logo {
                height: 65px;
                margin: 1.5rem 0;
            }
            p#description {
                font-size: 1.75rem;
                margin-bottom: 2.5rem;
                width: 525px;
            }
            a#call-to-action {
                font-size: 1.25rem;
                padding: 0.7rem 2.5rem 0.8rem 2.5rem;
            }
        }
    }
}
div#promo-underbanner {
    position: relative;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $blue;
    color: white;
    #ub-title {
        font-size: 1rem;
        font-weight: 500;
    }
    &::before {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -18%;
        width: 0;
        height: 0;
        border-bottom: solid 15px $blue;
        border-left: solid 15px transparent;
        border-right: solid 15px transparent;
        z-index: 2;
    }
}

// Garden range video

div#promo-banner {
    div#text-container {
        p#garden-title {
            font-size: 2rem;
            letter-spacing: -0.05rem;
            line-height: 0.8;
            text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.50);

            @include md-and-up {
                font-size: 2.5rem;
                font-weight: 500;
            }
            @include lg-and-up {
                font-size: 2.75rem;
                font-weight: 600;
            }
            @include xl-and-up {
                font-size: 4.25rem;
            }
            @include xxl-and-up {
                font-size: 4.625rem;

            }
        }
        p.garden-description {
            width: 100% !important;
            text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.50);
        }
    }
}

div#promo-banner {
    div#text-container {
        p#haircare-title {
            font-size: 2rem;
            letter-spacing: -0.05rem;
            line-height: 0.8;
	    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.50);
            @include md-and-up {
                font-size: 2.5rem;
                font-weight: 500;
            }
            @include lg-and-up {
                font-size: 2.75rem;
                font-weight: 500;
            }
            @include xl-and-up {
                font-size: 4.25rem;
            }
            @include xxl-and-up {
                font-size: 4.625rem;

            }
        }
        p.haircare-description {
            width: 100% !important;
            text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.50);
        }
        a#haircare-call-to-action {
            display: block;
            line-height: 1;
            font-size: 1.2rem;
            font-weight: 500;
            color: #fff;
            background-color: #22262a;
            border-radius: 5px;
            padding: 10px 30px;
            box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.50);
            width: fit-content;
        }
    }
}

// -- 2025 warehouse sale banner --
.cms-home #maincontent .homepage-warehouse-banner {
    padding: 0;
}
.homepage-warehouse-banner .row-full-width-inner {
    max-width: 100% !important;
}
.homepage-warehouse-banner figure {
    margin: 0 !important;
}
//-- end --


//Category Columns Section

.homepage-category-section {
    margin-top: 6.25rem;
    padding: 0;
    max-width: 1280px;
    @include media-breakpoint-down(md) {
        max-width: 100%;
        margin-top: 10px;
    }
    @include media-breakpoint-down(sm) {
        margin-top: 0;
    }
    .category-card {
        margin-bottom: 20px;
        @include media-breakpoint-down(md) {
            padding: 0;
            margin-bottom: 10px;
        }
        .category-content {
            flex-direction: column;
        }
        .card-category-image {
            position: relative;
            @include media-breakpoint-down(md) {
                width: 50%;
            }
            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }
        .card-container {
            background-color: #EEEEEE;
            padding: 2rem 3rem;
            margin-top: 5px;
            margin-bottom: 15px;
            @include media-breakpoint-down(md) {
                margin-top: 0;
                width: 50%;
                margin-bottom: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            @include media-breakpoint-down(sm) {
                width: 100%;
                padding: 1rem 2rem 2rem 2rem;
            }
            .category-button {
                background-color: black;
                color: white;
                padding: .7rem 2.1rem;
                text-align: center;
                width: 120px;
                font-weight: 500;
                @include media-breakpoint-down(md) {
                    padding: .4rem 2.1rem;
                }
            }
        }
        p {
            color: #5E696E;
            margin-bottom: 20px;
            margin-top: -20px;
            letter-spacing: -0.4px;
            line-height: 22px;
            @include media-breakpoint-down(md) {
                font-size: 14px;
                margin-top: 0px;
                line-height: 18px;
            }
            @include media-breakpoint-down(sm) {
                font-size: 16px;
                line-height: 20px;
            }
        }
        h2 {
            font-size: 3.75rem;
            letter-spacing: -1.8px;
            color: white;
            font-weight: 500;
            position: absolute;
            bottom: -10px;
            left: 45px;
            margin-bottom: 0;
            text-transform: uppercase;
            @include media-breakpoint-down(md) {
                left: 11%;
                bottom: 0px;
                font-size: 2.8rem;
            }
            @include media-breakpoint-down(sm) {
                left: 30px;
                font-size: 2.5rem;
                bottom: -6px;
            }
        }
    }
    .desktop {
        display: block;
        @include media-breakpoint-down(md) {
            display: none;
        }
        @include media-breakpoint-down(sm) {
            display: block;
            height: 340px;
            width: 100%;
            object-fit: cover;
        }
    }
    .tablet {
        display: none;
        @include media-breakpoint-down(md) {
            display: block;
            height: 300px;
            object-fit: cover;
        }
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}

.category-card:nth-child(odd) {
    .category-content {
        @include media-breakpoint-down(md) {
            flex-direction: row;
        }
        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
    }
}
.category-card:nth-child(even) {
    .category-content {
        @include media-breakpoint-down(md) {
            flex-direction: row-reverse;
        }
        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
    }
}

//Trustpilot Section
.container-fluid {
    @include media-breakpoint-down(sm) {
        padding: 0;
    }
}
.trustpilot-banner {
    background-image: url("/media/gtech/pages/homepage/trustpilot-banner-bg-2x.jpg");
    background-size: cover;
    height: 360px;
    margin-top: 130px;
    position: relative;
    background-position: top right;
    @include media-breakpoint-down(md) {
        margin-top: 20px;
        background-position: 60% 54%;
        background-size: 237%;
    }
    @include media-breakpoint-down(sm) {
        margin-top: 10px;
        width: 100%;
        height: auto;
        background-image: none;
    }
    .blue-banner {
        width: 350px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        height: 100%;
        left: 20%;
        background-color: #000132;
        padding: 1rem 2rem;
        color: white;
        text-align: center;
        @include media-breakpoint-down(lg) {
            left: 7%;
        }
        @include media-breakpoint-down(md) {
            left: 0%;
        }
        @include media-breakpoint-down(sm) {
            position: relative;
            width: 100%;
            padding: 1rem 1rem 2rem 1rem;
        }
        @include media-breakpoint-down(xs) {
            position: relative;
            width: 100%;
        }
        p {
            text-align: left;
            margin-left: 15px;
            margin-top: -20px;
            @include media-breakpoint-down(md) {
                max-width: 70%;
            }
            @include media-breakpoint-down(sm) {
                max-width: 65%;
                margin-left: auto;
                margin-right: auto;
                text-align: center;
                margin-top: -25px;
                line-height: 18px;
            }
        }
    }
    .trustpilot-widget {
        @include media-breakpoint-down(sm) {
            margin-left: 22.5px;
        }
    }
    p {
        font-size: 1.375rem;
        line-height: 24px;
        letter-spacing: -0.77px;
        @include media-breakpoint-down(md) {
            font-size: 1rem;
            line-height: 22px;
        }
        @include media-breakpoint-down(sm) {
            width: 80%;
            margin: auto;
            font-size: 1rem;
        }
    }
    .customer-quotes {
        display: flex;
        align-items: center;
        height: 100%;
        position: absolute;
        left: 42%;
        @media only screen and (max-width: 1550px) {
            left: 46%;
        }

        @include media-breakpoint-down(md) {
            left: 355px;
        }
        @include media-breakpoint-down(sm) {
            position: relative;
            left: 0;
            background-image: url("/media/gtech/pages/homepage/trustpilot-banner-bg-2x.jpg");
            background-size: 500%;
            background-position: 70%;
        }
    }
    .container {
        max-width: 1000px;
    }
    .main-customer-review {
        color: white;

        @include media-breakpoint-down(sm) {
            display: flex;
            flex-direction: column;
            padding: 4rem 1rem;
            text-align: center;
        }
        h2 {
            font-size: 3rem;
            letter-spacing: -1px;
            @include media-breakpoint-down(md) {
                font-size: 1.375rem;
            }
            @include media-breakpoint-down(sm) {
                font-size: 2rem;
            }
        }
        img {
            vertical-align: text-top;
            margin-right: 10px;
            @include media-breakpoint-down(md) {
                width: 84px;
                margin-bottom: 15px;
                object-fit: contain;
            }
        }
        .trustpilot-stars {
            font-weight: 500;
            display: flex;
            align-items: center;
            span {
                margin-top: 0;
                margin-bottom: 9px;
            }
            @include media-breakpoint-down(sm) {
                justify-content: center;
                margin-top: 15px;
            }
        }
        .customer-details-row {
            width: 90%;
            font-size: 22px;
            flex-direction: row;
            @include media-breakpoint-down(lg) {
                width: 90%;
            }
            @include media-breakpoint-down(md) {
                width: 100%;
                justify-content: center;
                flex-direction: column;
                font-size: 1rem;
            }
            .customer-info {
                display: flex;
                flex-direction: row;
                margin-left: 25px;
                @include media-breakpoint-down(md) {
                    margin-left: 0;
                }
                @include media-breakpoint-down(sm) {
                    margin: auto;
                    justify-content: center;
                    align-items: center;
                }
                .customer-name {
                    margin-right: 10px;
                    margin-left: 15px;
                    @include media-breakpoint-down(md) {
                        margin-left: 0;
                    }
                }
            }
            .customer-name:before {
                content: url("/media/gtech/pages/homepage/home-reviews-name.svg");
                margin-right: 5px;
            }
            .customer-location:before {
                content: url("/media/gtech/pages/homepage/home-reviews-location.svg");
                margin-right: 5px;

            }
        }
    }
}

//Blog Section
.blog-section {
    background-color: white;
    h2 {
        color: $gtech-headings;
        font-size: 40px;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 1.5rem;
        @include media-breakpoint-down(md) {
            font-size: 1.625rem;
            margin-bottom: 0.55rem;
        }
    }
    .blog-container {
        @include media-breakpoint-down(md) {
            padding-top: 3.8rem;
            padding-bottom: 3.8rem;
        }
        @include media-breakpoint-down(md) {
            padding-top: 3.5rem;
            padding-bottom: 3.5rem;
        }
        @include media-breakpoint-down(sm) {
            text-align: center;
            padding-top: 3rem;
            padding-bottom: 0;
        }
    }
    .slick-block-carousel {
        max-width: 98%;
    }
    .slick-slide {
        padding: .5rem;
        h3 {
            margin-top: 30px;
            margin-bottom: 15px;
            font-size: 30px;
            letter-spacing: -1px;
            line-height: 30px;
            @include media-breakpoint-down(md) {
                font-size: 1.25rem;
                line-height: 20px;
                margin-bottom: 10px;
            }
            @include media-breakpoint-down(sm) {
                margin-top: 20px;
            }
        }
        p {
            margin-bottom: 20px;
            color: $gtech-grey;
            line-height: 1.4;
            @include media-breakpoint-down (md) {
                line-height: 1.2;
                font-size: 0.875rem;
            }
        }
        h4 {
            font-size: 1.1rem;
            text-decoration: underline;
            @include media-breakpoint-down(md) {
                display: none;
            }
        }
    }
    .slick-prev {

        left: -55px;
        position: absolute;
        z-index: 10;
        cursor: pointer;
        display: none !important;
        @include media-breakpoint-down(md) {
            left: -35px;
        }
    }

    .slick-next {
        right: -20px;
        position: absolute;
        z-index: 10;
        @include media-breakpoint-down(md) {
            right: -10px;

        }
        &:before {
            display: none;
        }
    }

    .slick-arrow {
        height: 100px;
        background-color: $gtech-headings;
        width: 50px;
        top: 27%;
        @include media-breakpoint-down(lg) {
            top: 22%;
        }
        @include media-breakpoint-down(md) {
            height: 23%;
            width: 32px;
            height: 65px;
            top: 24%;
        }
        @include media-breakpoint-down(sm) {
            display: none !important;
        }
        img {
            width: 50%;
        }
    }
}



//Sales

.example-sale-banner {
    div {
        height: 100%;
    }
    h2 {
        font-size: 70px;
        color: white;
        font-weight: 600;
    }
    height: 856px !important;
    @include media-breakpoint-down(md) {
        height: 397px !important;
    }
    @include media-breakpoint-down(sm) {
        height: 530px !important;
    }
}

body.hide-scrollbar {
    overflow-y: hidden;
}

// New Page Builder Styles - TH 28/03/22
#html-body.cms-home {
    .platinum-video {
        display: none;
        height: 1080px;
        background-size: 3000px 1080px;
        padding: 0;
        margin-bottom: 10px;
        .content {
            display: block;
            padding: 70px;
            cursor: pointer;
            img {
                margin-bottom: 11px; // TODO 20px - Extra 9 pixels empty height..?
                width: 150px;
                height: 150px;
            }
        }
        h2 {
            font-size: 4.375rem;
            font-weight: 500;
            color: white;
            margin-bottom: 15px;
            line-height: 1;
        }
        p {
            font-size: 2.5rem;
            font-weight: 500;
            color: white;
            margin-bottom: 15px;
            line-height: 1;
        }
        .lightbox {
            position: fixed;
            width: 100vw;
            height: 100vh;
            top: 0;
            left: 0;
            z-index: 50;
            background-color: transparentize(black, 0.25);
        }
        .close-button {
            position: absolute;
            top: 0;
            right: 0;
            padding: 20px;
            cursor: pointer;
        }
        .close-button > img {
            width: 50px;
        }
        .hidden {
            display: none;
        }
        video {
            position: absolute;
            width: 75vw;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        @include media-breakpoint-down(lg) {
            height: 756px;
            background-size: 2100px 756px;
        }
        @include media-breakpoint-down(md) {
            height: 378px;
            background-size: 1050px 378px;
        }
    }

    .meet-the-inventor {
        height: 800px;
        background-size: 3000px 800px;
        padding-left: 2rem;
        h2 {
            font-size: 3.75rem;
            line-height: 3.75rem;
            margin-top: 6.5rem;
        }
        p {
            width: 635px;
            font-size: 1.75rem;
            line-height: 1.75rem;
        }
        a {
            color: white;
        }

        color: white;

        .nick-handwriting {
            width: 196px;
            height: 140px;
        }

        @include media-breakpoint-down(lg) {
            h2 {
                font-size: 2.25rem;
                line-height: 2.25rem;
                margin-top: 6.5rem;
            }
            p {
                width: 398px;
                font-size: 1.125rem;
                line-height: 1.125rem;
            }
            height: 560px;
            background-size: 2100px 560px;
            .nick-handwriting {
                width: 117px;
                height: 84px;
            }
        }
        @include media-breakpoint-down(md) {
            h2 {
                font-size: 1.5rem;
                line-height: 1.5rem;
                margin-top: 2.875rem;
            }
            p {
                width: 304px;
                font-size: 1rem;
                line-height: 1rem;
            }
            height: 364px;
            background-size: 1365px 364px;
            .meet-inventor-text {  p {font-size: 0.775rem;}}
            .nick-handwriting {
                width: 76px;
                height: 55px;
            }
        }
        @include media-breakpoint-down(sm) {
            h2 {
                font-size: 1.375rem;
                line-height: 1.375rem;
                margin-top: 1rem;
            }
            height: 273px;
            background-size: 1024px 273px;
            .meet-inventor-text { display: none;}
            .nick-handwriting {
                width: 76px;
                height: 55px;
            }
        }
    }
    .inventor-spacer {
        min-height: 100px;
    }
    .category-row {
        .pagebuilder-column {
            padding: 1.25rem;
        }

        .title {
            p {
                font-family: 'canada-type-gibson';
                font-size: 3.75rem;
                @include media-breakpoint-down(md) {
                    font-size: 1.5rem;
                }
                line-height: 3.75rem;
                text-transform: uppercase;
            }
        }
        .image { width: 611px; height: 500px;}
        .caption { p {font-size: 1rem; line-height: 1.375rem;} }
        .pagebuilder-button-link { font-size: 1.125rem; padding:0.55rem 3rem; color: white; background-color: $gtech-headings; width: fit-content; p {display:inline; a { color: white;}} }
    }
    .blog-section {
        h2 {
            font-weight: normal;
            @include media-breakpoint-up(xl) { font-size: 2.5rem; line-height: 3.5rem;}
            @include media-breakpoint-only(lg) { font-size: 2.5rem; line-height: 3.5rem;}
            @include media-breakpoint-only(md) { font-size: 1.5rem; line-height: 1.5rem;}
            @include media-breakpoint-down(sm) { font-size: 1.25rem; line-height: 1.25rem;}
        }
        .blog-listing {
            h3 {
                font-size: 1.875rem; line-height: 3.375rem;
            }
            p {
                font-size: 1rem;
            }
            h4 {
                a {
                    font-size: 1.125rem; line-height: 3.375rem;
                }
            }
        }
    }
}

.systemk9-tv-banner{




    h1{
        color: black;
        font-weight: 500;
        font-size: 5em;
        margin-bottom: 0;
        @include media-breakpoint-only(md) {
            font-size: 4rem;
        }
        @include media-breakpoint-down(sm) {
            visibility: hidden;
        }
    }


    h2{
        color: black;
        font-size: 3em;
        font-weight: 500;
        margin-bottom: 0;
        @include media-breakpoint-only(md) {
            font-size: 2rem;
        }
        @include media-breakpoint-down(sm) {
            visibility: hidden;
        }
    }

    .h2-white{
        color: white;
        font-size: 3em;
        font-weight: 500;
        margin-bottom: 1em;
        @include media-breakpoint-only(md) {
            font-size: 2rem;
        }
        @include media-breakpoint-down(sm) {
            visibility: hidden;
        }

    }

    .shop-now-button {
        background-color: white;
        color: black;
    }


}

//--Boxing day 2024 styling --
@media(min-width:960px) and (max-width:1280px) {
    .boxing-day-banner .banner-text-container.container {
        top: 15% !important;
    }
}
@media (max-width: 639.95px) {
    #banner-slider-carousel .owl-carousel .owl-item img {
        height:100% !important;
    }
}
@media(max-width:959px) {
    .boxing-day-logo {
        display: none !important;
    }
}
.boxing-day-logo {
    width: auto !important;
    margin-top: 1vw;
}
@media(max-width:959px) {
    .boxing-day-title {
        display: none !important;
    }
}

#boxing-day-delivery {
    font-size: 1.25rem;
    color: #fff;
    text-transform: uppercase;
    margin-top: -1vw;
}
.boxing-day-del {
    font-weight: 700;
}
@media(max-width:959px) {
    .logo-container #boxing-day-delivery {
            display: none;
    }
}
@media(min-width:960px) and (max-width:1280px) {
    .boxing-day-title {
        font-size: 1.875rem !important;
        margin-top: 4px !important;
        margin-bottom: 0 !important;
    }
}
.boxing-day-title {
    font-size: 3rem;
    text-transform: uppercase;
    color: #fff;
    margin-top: -1px;
}

.offer-text {
    font-weight: 700;
}
@media(max-width:959px) {
    #boxing-day-promo {
        display: none;
    }
}
@media(min-width:960px) and (max-width:1280px) {
    #boxing-day-promo {
        font-size: 1.5rem !important;
    }
}
#boxing-day-promo {
    font-size: 1.875rem;
    text-transform: uppercase;
    color: #fff;
    margin-top: 2vw;
    margin-bottom: 0 !important;
}
#boxing-day-code {
    font-weight: 700;
}
@media(max-width:768px) {
    /*.blue-underbanner {
        display: none !important;
    }*/
}
.offer-content p {
    color: #fff;
}
//--Boxing day 2024 styling end --

//-- Vday 2025 banner styling --
@include md-and-up {
    .vday-text-section {
        display: none;
    }
   .blue-underbanner {
        display: none !important;
    }
}
@include sm-and-up {
    .vday-text-section {
        display: none;
    }
    /*.blue-underbanner {
        display: none !important;
    }*/
}

@media(max-width:768px) {
    .vday-text-section {
        position: absolute;
        top: 4%;
        left: 4%;
    }
    .vday-text-section h1 {
        font-size: 3.5999rem;
        font-weight: bold;
        text-transform: uppercase;
        color: #fff;
        width: 100%;
        margin-bottom: 0;
    }
    p#vday-offer-text {
        font-family: "essonnes-display", serif;
        font-weight: 400;
        font-style: italic;
        color: #fff;
        font-size: 1.2rem;
        margin-top: -1rem;
        margin-bottom: 3px;
    }
    p#vday-banner-text {
        width: 70%;
        font-size: .8999rem;
        color: #fff;
        line-height: 18px;
        margin-bottom: 0;
    }
    p#vday-text {
        color: #fff;
        font-weight: 400;
        margin-bottom: 0;
    }

    p#vday-offer {
        color: #fff;
        text-transform: uppercase;
        font-size: 20px;
        margin-top: 15px;
    }
    p#vday-offer {
        color: #fff;
        text-transform: uppercase;
        font-size: 20px;
        margin-top: 15px;
    }
    p#vday-offer span {
        font-weight: bold;
    }
}
//-- Vday 2025 banner styling end --

//--Spring sale 2025 banner styling  --
#banner-slider-carousel .default-banner.spring-sale-banner {
    height: auto !important;
}
//--Spring sale 2025 banner end --
